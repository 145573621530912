import { ScrollScene } from "scrollscene";
import { gsap } from "gsap";

const pageTimeline = gsap.timeline({ paused: true });
const pageTimeline1 = gsap.timeline({ paused: true });
const pageTimeline2 = gsap.timeline({ paused: true });
const pageTimeline3 = gsap.timeline({ paused: true });
const pageTimeline4 = gsap.timeline({ paused: true });
const pageTimeline5 = gsap.timeline({ paused: true });

if (document.querySelector(".hero-image__background canvas")) {
  // Dust Particles Simulation by bionicoz based on
  // Basic Particle Animation
  // Author: Brandon John-Freso
  const parent = document.querySelector(".hero-image__background");
  const canvas = document.getElementById("canvas");
  const ctx = canvas.getContext("2d");

  const particles = [];
  const particleCount = 50;

  // Set the canvas size to match the parent element
  canvas.width = parent.offsetWidth * 0.4;
  canvas.height = parent.offsetHeight;

  // Particle object
  function Particle() {
    this.x = canvas.width * Math.random();
    this.y = canvas.height * Math.random();
    this.vx = 1.5 * Math.random() - 0.25;
    this.vy = 1.5 * Math.random() - 0.25;
    this.radius = 3 * Math.random() + 1;
  }

  // Draw particle on canvas
  Particle.prototype.draw = function () {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
    ctx.fill();
  };

  // Scale the particle effect so that it fits within the canvas
  const scale =
    Math.min(canvas.width / canvas.width, canvas.height / canvas.height) / 2;

  // Center the particle effect within the canvas
  const x = canvas.width / 2;
  const y = canvas.height / 2;

  // Update particle position
  Particle.prototype.update = function () {
    this.x += this.vx * scale;
    this.y += this.vy * scale;

    // Bounce off edges
    if (this.x + this.radius > canvas.width || this.x - this.radius < 0) {
      this.vx = -this.vx;
    }
    if (this.y + this.radius > canvas.height || this.y - this.radius < 0) {
      this.vy = -this.vy;
    }
  };

  // Create particles
  for (let i = 0; i < particleCount; i++) {
    particles.push(new Particle());
  }

  // Animation loop
  function animate() {
    requestAnimationFrame(animate);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < particleCount; i++) {
      particles[i].update();
      particles[i].draw();
    }
  }

  // Start animation
  animate();
}

// Homepage Hero Animation
window.onload = function () {
  const heroCover = document.querySelector(".hero-image__cover");
  let deviceWidth = window.innerWidth;

  switch (true) {
    case deviceWidth <= 575:
      gsap.set(heroCover, { x: "0%" });
      break;
    case deviceWidth > 575 && deviceWidth <= 767:
      gsap.to(heroCover, { duration: 1.75, x: "65%", ease: "power2.out" });
      break;
    case deviceWidth > 768 && deviceWidth <= 1023:
      gsap.to(heroCover, { duration: 1.75, x: "70%", ease: "power2.out" });
      break;
    case deviceWidth > 1024 && deviceWidth <= 1199:
      gsap.to(heroCover, { duration: 1.75, x: "63%", ease: "power2.out" });
      break;
    case deviceWidth > 1200 && deviceWidth <= 1399:
      gsap.to(heroCover, { duration: 1.75, x: "60%", ease: "power2.out" });
      break;
    case deviceWidth >= 1999:
      gsap.to(heroCover, { duration: 1, x: "45%", ease: "power2.out" });
      break;
    default:
      gsap.to(heroCover, { duration: 1.75, x: "65%", ease: "power2.out" });
      break;
  }
};

// Featured Content Animation
if (document.querySelector(".featured_content.type1")) {
  //featured content type 1 parallax
  const featuredContentTypeOneContent = document.querySelectorAll(
    ".featured_content.type1 .img_holders img.slide"
  );
  const featuredContentTypeOneTrigger = document.querySelectorAll(
    ".featured_content.type1"
  );
  const numOfFeaturedItems = featuredContentTypeOneTrigger.length;

  if (numOfFeaturedItems >= 5) {
    pageTimeline5.to(featuredContentTypeOneContent[4], {
      y: -100,
      duration: 1,
      ease: "power2.out"
    });

    const scrollScene5 = new ScrollScene({
      triggerElement: featuredContentTypeOneTrigger[4],
      toggle: {
        element: featuredContentTypeOneTrigger[4],
        className: "on",
        reverse: true
      },
      gsap: {
        timeline: pageTimeline5
      },
      duration: 1200,
      offset: 400
    });
  }
  if (numOfFeaturedItems >= 4) {
    pageTimeline4.to(featuredContentTypeOneContent[3], {
      y: -100,
      duration: 1,
      ease: "power2.out"
    });

    const scrollScene4 = new ScrollScene({
      triggerElement: featuredContentTypeOneTrigger[3],
      toggle: {
        element: featuredContentTypeOneTrigger[3],
        className: "on",
        reverse: true
      },
      gsap: {
        timeline: pageTimeline4
      },
      duration: 1200,
      offset: 400
    });
  }
  if (numOfFeaturedItems >= 3) {
    pageTimeline3.to(featuredContentTypeOneContent[2], {
      y: -100,
      duration: 1,
      ease: "power2.out"
    });

    const scrollScene3 = new ScrollScene({
      triggerElement: featuredContentTypeOneTrigger[2],
      toggle: {
        element: featuredContentTypeOneTrigger[2],
        className: "on",
        reverse: true
      },
      gsap: {
        timeline: pageTimeline3
      },
      duration: 1200,
      offset: 400
    });
  }
  if (numOfFeaturedItems >= 2) {
    pageTimeline2.to(featuredContentTypeOneContent[1], {
      y: -100,
      duration: 1,
      ease: "power2.out"
    });

    const scrollScene2 = new ScrollScene({
      triggerElement: featuredContentTypeOneTrigger[1],
      toggle: {
        element: featuredContentTypeOneTrigger[1],
        className: "on",
        reverse: true
      },
      gsap: {
        timeline: pageTimeline2
      },
      duration: 1200,
      offset: 400
    });
  }
  pageTimeline1.to(featuredContentTypeOneContent[0], {
    y: -100,
    duration: 1,
    ease: "power2.out"
  });

  const scrollScene1 = new ScrollScene({
    triggerElement: featuredContentTypeOneTrigger[0],
    toggle: {
      element: featuredContentTypeOneTrigger[0],
      className: "on",
      reverse: true
    },
    gsap: {
      timeline: pageTimeline1
    },
    duration: 1200,
    offset: 400
  });
}

//Statistics animations
if (document.querySelector(".statistics")) {
  let statsAnimated = false;
  const statsScrollScene = new ScrollScene({
    triggerElement: document.querySelector(".statistics .stats")
  });
  statsScrollScene.Scene.on("enter", function (event) {
    if (!statsAnimated) {
      startStatistics(1);
      statsAnimated = true;
    }
  });

  function Counter(selector, settings) {
    this.settings = Object.assign(
      {
        digits: 5,
        delay: 250, // ms
        direction: "" // ltr is default
      },
      settings || {}
    );

    var scopeElm = document.querySelector(selector);

    // generate digits markup
    var digitsHTML = Array(this.settings.digits + 1).join(
      '<div><b data-value="0"></b></div>'
    );
    scopeElm.innerHTML = digitsHTML;

    this.DOM = {
      scope: scopeElm,
      digits: scopeElm.querySelectorAll("b")
    };

    this.DOM.scope.addEventListener("transitionend", e => {
      if (e.pseudoElement === "::before" && e.propertyName == "margin-top") {
        e.target.classList.remove("blur");
      }
    });

    this.count();
  }

  Counter.prototype.count = function (newVal) {
    var countTo,
      className,
      settings = this.settings,
      digitsElms = this.DOM.digits;

    // update instance's value
    this.value = newVal || this.DOM.scope.dataset.value | 0;

    if (!this.value) return;

    // convert value into an array of numbers
    countTo = (this.value + "").split("");

    if (settings.direction == "rtl") {
      countTo = countTo.reverse();
      digitsElms = [].slice.call(digitsElms).reverse();
    }

    // loop on each number element and change it
    digitsElms.forEach(function (item, i) {
      if (+item.dataset.value != countTo[i] && countTo[i] >= 0)
        setTimeout(
          function (j) {
            var diff = Math.abs(countTo[j] - +item.dataset.value);
            item.dataset.value = countTo[j];
            if (diff > 3) item.className = "blur";
          },
          i * settings.delay,
          i
        );
    });
  };

  function startStatistics(sceneNum) {
    counter = document.querySelector(".numCounter" + sceneNum);
    if (counter) {
      var counter = new Counter(".numCounter" + sceneNum, {
        direction: "rtl",
        delay: 200,
        digits: parseInt(counter.dataset.count)
      });
    }
    if (sceneNum <= 5) {
      setTimeout(() => {
        startStatistics(sceneNum + 1);
      }, 150);
    }
  }

  function randomCount() {
    counter.count(getRandomNum(0, 9999999));
  }

  function getRandomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}

//Icon Block Image Scale In
if (document.querySelector(".icon-img")) {
  const iconBlocks = document.querySelectorAll(".icon-img");
  let timerSet = 0;
  iconBlocks.forEach(function (el, index) {
    let iconScrollScreen = new ScrollScene({
      triggerElement: el
    });
    iconScrollScreen.Scene.on("enter", function (event) {
      setTimeout(function () {
        startIconAnimation(el, timerSet);
      }, 125 * (index + 1));
    });
  });
  function startIconAnimation(element, timer) {
    element.classList.add("active");
  }
}
