const accordionButtons = document.querySelectorAll(".accordion-button");
accordionButtons.forEach(function (elem) {
  elem.addEventListener("click", function (e) {
    e.preventDefault();
    targetShow = this.dataset.bsTarget;
    const container = document.getElementById(targetShow.replace("#", ""));
    // elem.classList.toggle("collapse");
    if (!container.classList.contains("active")) {
      container.classList.add("active");
      container.style.height = "auto";

      let height = container.clientHeight + "px";

      container.style.height = "0px";

      setTimeout(function () {
        container.style.height = height;
      }, 0);
    } else {
      // container.style.height = "0px";
      container.style.height = "auto";

      let height = container.clientHeight + "px";

      container.style.height = height;

      setTimeout(function () {
        container.style.height = "0px";
      }, 0);
      container.addEventListener(
        "transitionend",
        function () {
          container.classList.remove("active");
        },
        {
          once: true
        }
      );
    }
  });
});
