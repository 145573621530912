import Glide from "@glidejs/glide";

if (document.querySelector(".experts.glide")) {
  var glide = new Glide(".experts.glide", {
    type: "slider",
    perView: 5,
    startAt: 0,
    focusAt: 0,
    gap: 50,
    breakpoints: {
      1200: {
        perView: 4,
        gap: 40
      },
      960: {
        perView: 3,
        gap: 30
      },
      768: {
        perView: 2,
        gap: 20
      },
      500: {
        perView: 1
      }
    }
  });

  glide.mount();
}
