var Isotope = require("isotope-layout");
const practicesSelector = document.getElementById("practices_sectors");

const allPracticeItems = document.body.querySelectorAll(
  "#practices_list .list .practice_item"
);

var practicesListElem = document.querySelector("#practices_list .list ul");

if (practicesListElem) {
  var practicesListIso = new Isotope(practicesListElem, {
    // options
    itemSelector: ".practice_item",
    layoutMode: "fitRows"
  });
  practicesSelector.addEventListener("change", filterPractices);
}

function filterPractices() {
  const practicesValue = practicesSelector.value;
  if (practicesValue !== 0 && practicesValue !== "0") {
    allPracticeItems.forEach(function (el) {
      sectorIds = el.dataset.sectors;
      sectorArray = sectorIds.split(",");
      if (sectorArray.includes(practicesValue)) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
  } else {
    allPracticeItems.forEach(function (el) {
      el.classList.add("active");
    });
  }
  practicesListIso.arrange({ filter: ".active" });
}
