const element = document.querySelectorAll(
  "nav.desktop .contents .container .col.l2.sectors .contents ul.sub li a"
);
const allNavColumns = document.body.querySelectorAll(
  "nav.desktop .contents .container .col"
);
const l1Nav = document.querySelectorAll(
  "nav.desktop .contents .container .col .contents ul.l1 li a.link"
);
const l1NavMobile = document.querySelectorAll(
  "nav.mobile .container .contents ul.l1 li a.link"
);
const l1HeaderNav = document.querySelectorAll("#desktop_nav ul.l1 li a.link");
const l3Column = document.querySelector(
  "nav.desktop .contents .container .col.l3 .contents"
);
const l3ColumnWrapper = document.querySelector(
  "nav.desktop .contents .container .col.l3"
);
const desktopNavCloseBtn = document.querySelector(
  "#desktop_nav #close_btn_holder"
);
const mobileNavCloseBtn = document.querySelector(
  "#mobile_nav #close_btn_holder_mobile"
);
const desktopNav = document.querySelector("nav.desktop");
const mobileNav = document.querySelector("nav.mobile");
const searchLinks = document.querySelectorAll("a.search");
const searchOverlay = document.getElementById("search_overlay");
document.getElementById("mobile_menu_icon").onclick = function (e) {
  this.classList.toggle("open");
  e.preventDefault();
  mobileNav.classList.toggle("open");
  document.body.classList.toggle("menu-open");
  document.querySelector("#mobile_nav a.search").classList.remove("open");
  searchOverlay.classList.remove("open");
  mobileNavCloseBtn.classList.remove("open");
};
createMobileNav();
currentMenuLevel = 1;
function createMobileNav() {
  currentSectionNumber = 0;
  allNavColumns.forEach(function (el) {
    let navStage = document.createElement("div");
    if (el.classList.contains("toplevel")) {
      navStage.classList.add("stage", "top");
      let navStageContainer = document.createElement("div");
      navStageContainer.classList.add("container");
      navStageContainer.innerHTML = el.innerHTML;
      navStage.appendChild(navStageContainer);
      document.querySelector("nav.mobile").appendChild(navStage);
      document.querySelector("nav.mobile .stage.top .container").innerHTML =
        el.innerHTML;
    } else {
      navStage.setAttribute("data-id", el.dataset.id);
      navStage.classList.add("stage", "off");
      if (el.classList.contains("sectors")) {
        navStage.classList.add("sectors");
      }
      let navStageContainer = document.createElement("div");
      navStageContainer.classList.add("container");
      let returnBtn =
        currentSectionNumber !== el.dataset.id
          ? '<a class="return">Main Menu</a>'
          : "";
      navStageContainer.innerHTML = returnBtn + el.innerHTML;
      if (currentSectionNumber !== el.dataset.id) {
        navStage.appendChild(navStageContainer);
        document.querySelector("nav.mobile").appendChild(navStage);
      } else {
        document
          .querySelector(
            "nav.mobile .stage.off[data-id='" + el.dataset.id + "']"
          )
          .appendChild(navStageContainer);
      }
    }
    currentSectionNumber = el.dataset.id;
  });
  setMobileNav();
}
element.forEach(function (el) {
  el.addEventListener("click", function (e) {
    if (this.nextSibling.innerHTML) {
      e.preventDefault();
      const newList = document.createElement("ul");
      clearNav(2);
      el.parentNode.classList.add("nav-open");
      el.parentNode.parentNode.classList.add("nav-open");
      el.classList.add("nav-open");
      newList.classList.add("sub");
      newList.classList.add("l3");
      newList.innerHTML =
        "<h4><a href='" +
        this.href +
        "'>" +
        this.text +
        "</a></h4>" +
        this.nextSibling.innerHTML;
      l3Column.innerHTML = newList.outerHTML;
      l3ColumnWrapper.classList.add("active");
    }
  });
});
l1Nav.forEach(function (elem) {
  elem.addEventListener("click", function (e) {
    if (parseInt(this.dataset.id) !== 231) {
      e.preventDefault();
      searchOverlay.classList.remove("open");
      sectionId = this.dataset.id;
      clearNav(1);
      elem.parentNode.classList.add("nav-open");
      elem.classList.add("nav-open");
      headerNavElem = document.querySelector(
        "#desktop_nav ul.l1 li a[data-id='" + sectionId + "']"
      );
      headerNavElem.parentNode.classList.add("nav-open");
      headerNavElem.classList.add("nav-open");
      if (
        document.body.querySelectorAll(
          "nav.desktop .contents .container .col.active"
        ).length > 0
      ) {
        const eachSelected = document.body.querySelectorAll(
          "nav.desktop .contents .container .col.active"
        );
        eachSelected.forEach(navActiveItem => {
          navActiveItem.classList.remove("active");
        });
      }
      if (
        document.body.querySelector(".col.first[data-id='" + sectionId + "']")
      ) {
        document.body
          .querySelector(".col.first[data-id='" + sectionId + "']")
          .classList.add("active");
      }
      if (
        document.body.querySelector(".col.last[data-id='" + sectionId + "']")
      ) {
        document.body
          .querySelector(".col.last[data-id='" + sectionId + "']")
          .classList.add("active");
      }
    }
  });
});
document
  .querySelector("#desktop_nav #close_btn_holder a")
  .addEventListener("click", function (e) {
    e.preventDefault();
    desktopNav.classList.remove("open");
    document
      .querySelector("#desktop_nav #close_btn_holder")
      .classList.remove("open");
    searchOverlay.classList.remove("open");
    document.body.classList.remove("menu-open");
  });
l1HeaderNav.forEach(function (elem) {
  elem.addEventListener("click", function (e) {
    if (parseInt(this.dataset.id) !== 231) {
      e.preventDefault();
      desktopNav.classList.add("open");
      document
        .querySelector("#desktop_nav #close_btn_holder")
        .classList.add("open");
      document.body.classList.add("menu-open");
      sectionId = this.dataset.id;
      clearNav(1);
      elem.parentNode.classList.add("nav-open");
      elem.classList.add("nav-open");
      mainNavElem = document.querySelector(
        "nav.desktop .contents .container .col .contents ul.l1 li a[data-id='" +
          sectionId +
          "']"
      );
      mainNavElem.parentNode.classList.add("nav-open");
      mainNavElem.classList.add("nav-open");
      if (
        document.body.querySelectorAll(
          "nav.desktop .contents .container .col.active"
        ).length > 0
      ) {
        const eachSelected = document.body.querySelectorAll(
          "nav.desktop .contents .container .col.active"
        );
        eachSelected.forEach(navActiveItem => {
          navActiveItem.classList.remove("active");
        });
      }
      if (
        document.body.querySelector(".col.first[data-id='" + sectionId + "']")
      ) {
        document.body
          .querySelector(".col.first[data-id='" + sectionId + "']")
          .classList.add("active");
      }
      if (
        document.body.querySelector(".col.last[data-id='" + sectionId + "']")
      ) {
        document.body
          .querySelector(".col.last[data-id='" + sectionId + "']")
          .classList.add("active");
      }
    }
  });
});
function clearNav(level) {
  if (level == 1) {
    l1Nav.forEach(elem => {
      elem.classList.remove("nav-open");
      elem.parentNode.classList.remove("nav-open");
      elem.parentNode.parentNode.classList.add("nav-open");
    });
    l1HeaderNav.forEach(elem => {
      elem.classList.remove("nav-open");
      elem.parentNode.classList.remove("nav-open");
      elem.parentNode.parentNode.classList.add("nav-open");
    });
  }
  if (level == 2) {
    element.forEach(elem => {
      elem.classList.remove("nav-open");
      elem.parentNode.classList.remove("nav-open");
      elem.parentNode.parentNode.classList.add("nav-open");
    });
  }
}
function setMobileNav() {
  const l1NavMobile = document.querySelectorAll(
    "nav.mobile .container .contents ul.l1 li a"
  );
  l1NavMobile.forEach(function (elem) {
    if (parseInt(elem.dataset.id) !== 231) {
      elem.addEventListener("click", function (e) {
        e.preventDefault();
        sectionId = this.dataset.id;
        clearNav(1);
        currentMenuLevel++;
        document
          .querySelector("nav.mobile .stage.top")
          .classList.add("transition", "exit");
        document
          .querySelector(".stage.off[data-id='" + sectionId + "']")
          .classList.add("transition", "active");
        document
          .querySelector(".stage.off[data-id='" + sectionId + "']")
          .classList.remove("off");
        // alert(sectionId);
      });
    } else {
    }
  });
  const mobileCloseBtns = document.querySelectorAll(
    "nav.mobile .container a.return"
  );
  mobileCloseBtns.forEach(function (elem) {
    elem.addEventListener("click", function (e) {
      e.preventDefault();
      document
        .querySelector("nav.mobile .stage.active")
        .classList.add("transition", "off");
      document
        .querySelector("nav.mobile .stage.active")
        .classList.remove("active");
      document
        .querySelector("nav.mobile .stage.exit")
        .classList.add("transition");
      document.querySelector("nav.mobile .stage.exit").classList.remove("exit");
      currentMenuLevel--;
      // alert(currentMenuLevel);
    });
  });
  const sectorsMobileNav = document.querySelectorAll(
    "nav.mobile .stage.sectors .container .contents ul li a"
  );
  sectorsMobileNav.forEach(function (elem) {
    elem.addEventListener("click", function (e) {
      e.preventDefault();
      let navStage = document.createElement("div");
      navStage.classList.add(
        "stage",
        "off",
        "transition",
        "active",
        "sectorsub"
      );
      let navStageContainer = document.createElement("div");
      navStageContainer.classList.add("container");
      let returnBtn = "<a class='return'>Sectors</a>";
      navStageContainer.innerHTML =
        returnBtn +
        "<h4 class='l3'><a href='" +
        this.href +
        "'>" +
        this.text +
        '</a></h4><ul class="sub l3">' +
        this.nextSibling.innerHTML +
        "</ul>";
      navStage.appendChild(navStageContainer);
      setTimeout(() => {
        navStage.classList.remove("off");
      }, 1);
      document.querySelector("nav.mobile").appendChild(navStage);
      document
        .querySelector("nav.mobile .stage.sectors")
        .classList.remove("active");
      document.querySelector("nav.mobile .stage.sectors").classList.add("exit");
      currentMenuLevel++;
      createSubClickAction();
    });
  });
}
function createSubClickAction() {
  const subReturnLink = document.querySelector(
    "nav.mobile .stage.sectorsub a.return"
  );
  const subSectorMenu = document.querySelector("nav.mobile .stage.sectorsub");
  const sectorMenu = document.querySelector("nav.mobile .stage.sectors");
  subReturnLink.addEventListener("click", function (e) {
    e.preventDefault();
    subSectorMenu.classList.add("transition");
    sectorMenu.classList.add("transition");
    setTimeout(() => {
      currentMenuLevel--;
      subSectorMenu.classList.add("off");
      subSectorMenu.classList.remove("active");
      sectorMenu.classList.add("active");
      sectorMenu.classList.remove("exit");
    }, 1);
    setTimeout(() => {
      subSectorMenu.remove();
    }, 151);
  });
}

searchLinks.forEach(function (el) {
  el.addEventListener("click", function (e) {
    document
      .querySelector("#desktop_nav #close_btn_holder")
      .classList.add("open");
    searchOverlay.classList.add("open");
    document.body.classList.add("menu-open");
    desktopNav.classList.remove("open");
    mobileNav.classList.remove("open");
    document.querySelector("#mobile_nav a.search").classList.add("open");
    mobileNavCloseBtn.classList.add("open");
    document.getElementById("mobile_menu_icon").classList.toggle("open");
  });
});
mobileNavCloseBtn.addEventListener("click", function (e) {
  document
    .querySelector("#desktop_nav #close_btn_holder")
    .classList.remove("open");
  searchOverlay.classList.remove("open");
  document.body.classList.remove("menu-open");
  document.querySelector("#mobile_nav a.search").classList.remove("open");
  mobileNavCloseBtn.classList.remove("open");
});
