import Headroom from "headroom.js";
import supportsWebP from "supports-webp";
var healthAdvancesHeader = document.getElementById("header");
var headroom = new Headroom(healthAdvancesHeader);
headroom.init();

supportsWebP.then(supported => {
  if (supported) {
    document.documentElement.classList.add("webp");
  } else {
    document.documentElement.classList.add("standard");
  }
});
