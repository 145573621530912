import Glide from "@glidejs/glide";

if (document.querySelector(".testimonials")) {
  const testimonialElements = document.querySelectorAll(".testimonials");
  testimonialElements.forEach(function (elem) {
    if (elem.dataset.count > 1) {
      var testimonialGlide = new Glide(".testimonials", {
        type: "carousel",
        perView: 1,
        startAt: 0,
        focusAt: 0,
        breakpoints: {
          1200: {
            perView: 1
          },
          960: {
            perView: 1
          },
          768: {
            perView: 1
          },
          500: {
            perView: 1
          }
        }
      });

      testimonialGlide.mount();
    }
  });
}
