import Glide from "@glidejs/glide";

if (document.querySelector(".relatedresources")) {
  var perViewCountMax = 4;
  if (parseInt(document.querySelector(".relatedresources").dataset.count) < 4) {
    perViewCountMax = parseInt(
      document.querySelector(".relatedresources").dataset.count
    );
  }
  var glide = new Glide(".relatedresources", {
    type: "slider",
    perView: perViewCountMax,
    startAt: 0,
    focusAt: 0,
    gap: 50,
    bound: true,
    breakpoints: {
      1200: {
        perView: perViewCountMax,
        gap: 40
      },
      960: {
        perView: 3,
        gap: 30
      },
      768: {
        perView: 2,
        gap: 10
      },
      500: {
        perView: 1
      }
    }
  });
  glide.mount();
}

var resizeEvent = new Event("resize");

window.dispatchEvent(resizeEvent);
