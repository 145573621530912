import Glide from "@glidejs/glide";

if (
  document.getElementById("featured_insight_holder") &&
  document.getElementById("featured_insight_holder").dataset.count > 1
) {
  var glide = new Glide("#featured_insight_holder", {
    type: "carousel",
    focusAt: "center",
    perView: 1
  });

  glide.mount();
}
